// 
// 
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.



// FIGMA STYLES
$color-green-1: rgba(60,186,88,1);
$color-green-2: rgba(50,103,69,1);
$color-green-3: rgba(177,227,188,1);
$color-yellow-1: rgba(255,180,0,1);
$color-yellow-2: rgba(128,101,34,1);
$color-yellow-3: rgba(255,225,153,1);
$color-coral-1: rgba(255,86,79,1);
$color-coral-2: rgba(128,63,65,1);
$color-coral-3: rgba(255,187,185,1);
$color-dark-dark: rgba(44,48,56,1);
$color-lighten-lighten: rgba(255,255,255,1);
$color-gray-1: rgba(247,249,252,1);
$color-gray-2: rgba(234,237,242,1);
$color-gray-3: rgba(170,173,177,1);
$color-gray-4: rgba(149,151,156,1);
$color-gray-5: rgba(85,90,100,1);
@mixin font-8-small-caps-semi {
	font-size:8px;
	font-family:"Nunito";
	font-weight:semibold;
}
@mixin font-12-body-1-semi {
	font-size:12px;
	font-family:"Nunito";
	font-weight:semibold;
	line-height:18px;
}
@mixin font-14-body-2-semi {
	font-size:14px;
	font-family:"Nunito";
	font-weight:semibold;
	line-height:24px;
}
@mixin font-14-body-2-bold {
	font-size:14px;
	font-family:"Nunito";
	font-weight:bold;
	line-height:24px;
}
@mixin font-16-lead-semi {
	font-size:16px;
	font-family:"Nunito";
	font-weight:semibold;
	line-height:24px;
}
@mixin font-16-lead-bold {
	font-size:16px;
	font-family:"Nunito";
	font-weight:bold;
	line-height:24px;
}
@mixin font-20-heading-2-semi {
	font-size:20px;
	font-family:"Nunito";
	font-weight:semibold;
	line-height:30px;
}
@mixin font-20-heading-2-bold {
	font-size:20px;
	font-family:"Nunito";
	font-weight:bold;
	line-height:30px;
}
@mixin font-40-heading-1-bold {
	font-size:40px;
	font-family:"Nunito";
	font-weight:bold;
	line-height:48px;
}
@mixin font-54-display-bold {
	font-size:54px;
	font-family:"Nunito";
	font-weight:bold;
	line-height:56px;
}
@mixin font-8-small-caps-bold {
	font-size:8px;
	font-family:"Nunito";
	font-weight:bold;
}





// Color Scheme
// Change the hex values below to alter the main color scheme.

$primary:       #3CBA58;
$primary-2:     #326745;
$primary-3:     #2C3038;

// Do not change or remove the $theme-color-scheme or $theme-colors maps below

$theme-color-scheme: (
    "primary":    $color-green-1,
    "primary-2":  $color-green-2,
    "primary-3":  $color-dark-dark
);

$theme-colors: (
    "primary":    $color-green-1,
    "primary-2":  $color-green-2,
    "primary-3":  $color-dark-dark,
);