// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

body{
    background: $color-gray-1;
}

.pricing-table-section .row-title{
        @include font-12-body-1-semi();
        color: $color-gray-4;
    }

.pricing-table-section .row-content{
    @include font-14-body-2-bold();
    color: $color-dark-dark;
}

.custom-radio {
    @at-root td {
        border-radius: 5px;
    }
}

.heading-2-semi-20{
    @include font-20-heading-2-semi();
    color: $color-dark-dark;
    margin-bottom: 0;
}

.body-1-semi-12{
    @include font-12-body-1-semi();
    color: $color-gray-5;
}

.display-5{
    @include font-54-display-bold();
    color: $color-dark-dark;
}

.btn{
    border-radius: 5px;
}

.cell-elmt{
    border-radius: 5px;
    width: 100%
}

.display-4{
    @include font-40-heading-1-bold();
    color: $color-dark-dark;
}

.body-2-semi{
    @include font-14-body-2-semi();
}

.body-2-bold{
    @include font-14-body-2-bold();
}

.dark-dark{
    color: $color-dark-dark;
}

.gray-5{
    color: $color-gray-5;
}

.processo-compra{
    padding-left: 0.1em;
    list-style: none;
}


.form-control{
    font-size: 12px;
}
